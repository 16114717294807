//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Box, Stack, Typography } from '@mui/material';
import github_logo from '../assets/github-mark-white.svg';
import logo from '../assets/logo.svg';
import InsetArea from '../components/InsetArea';
import LetterGlitch from '../components/LetterGlitch';

export const prevent_scroll = {
	overflowX: 'hidden',
	overflowY: 'hidden',
	touchAction: 'none',
	WebkitOverflowScrolling: 'none',
	overscrollBehavior: 'none',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	'&::-webkit-scrollbar': { display: 'none' },
};

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function DemoPage() {
	const email = 'contact@attentionlab.com';
	const github_url = 'https://attlab-org.github.io';

	return (
		<InsetArea fill_top={true}>
			<Box zIndex={10} position="absolute" top={0} left={0} width="100%" height="100%">
				<LetterGlitch glitchColors={['#2b4539', '#61dca3', '#61b3dc']} glitchSpeed={10} centerVignette={true} outerVignette={false} smooth />;
			</Box>

			<Box zIndex={100} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" padding="30px">
				<Stack width="100%" maxWidth="900px" height="100%" maxHeight="900px" alignItems="center" justifyContent="space-between" spacing={1}>
					<Stack flex={1} display="flex" justifyContent="center" alignItems="center" spacing={2}>
						<Box component="img" src={logo} width="230px" />

						<Typography variant="h6" color="grey">
							Demo Site Root
						</Typography>

						<Typography variant="body1" color="white" width="70%" textAlign="center">
							If you would like a demo, please request permission via email.
						</Typography>

						<Typography variant="body1" component="a" color="white" href={`mailto:${email}`} sx={{ color: 'white', textDecoration: 'none', '&:hover': { color: 'white' } }}>
							{email}
						</Typography>
					</Stack>

					<Stack position="absolute" bottom="40px" alignItems="center" sx={{ background: 'radial-gradient(circle, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4))' }}>
						<Box display="flex" justifyContent="center" alignItems="center" gap="6px">
							<Box component="img" src={github_logo} width="24px" />

							<Typography variant="body2" component="a" color="white" href={github_url} sx={{ color: 'white', textDecoration: 'none', '&:hover': { color: 'white' } }}>
								Our GitHub Repository
							</Typography>
						</Box>

						<Typography variant="body2" color="white">
							{'© Attention Lab.'}
						</Typography>
					</Stack>
				</Stack>
			</Box>
		</InsetArea>
	);
}
