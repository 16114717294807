export function getRGBValues(color: string): { r: number; g: number; b: number } {
	if (!color) return { r: 0, g: 0, b: 0 };

	if (color.startsWith('#')) {
		const hex = color.replace('#', '');
		if (hex.length === 3) {
			const r = parseInt(hex[0] + hex[0], 16);
			const g = parseInt(hex[1] + hex[1], 16);
			const b = parseInt(hex[2] + hex[2], 16);
			return { r, g, b };
		}
		if (hex.length === 6) {
			const r = parseInt(hex.slice(0, 2), 16);
			const g = parseInt(hex.slice(2, 4), 16);
			const b = parseInt(hex.slice(4, 6), 16);
			return { r, g, b };
		}
	}

	const rgbMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/i);
	if (rgbMatch) {
		return {
			r: parseInt(rgbMatch[1]),
			g: parseInt(rgbMatch[2]),
			b: parseInt(rgbMatch[3]),
		};
	}

	return { r: 0, g: 0, b: 0 };
}

export function getInterpolatedColor(startColor: string, endColor: string, ratio: number): string {
	const start = getRGBValues(startColor);
	const end = getRGBValues(endColor);

	const clampedRatio = Math.max(0, Math.min(1, ratio));

	const r = Math.round(start.r + (end.r - start.r) * clampedRatio);
	const g = Math.round(start.g + (end.g - start.g) * clampedRatio);
	const b = Math.round(start.b + (end.b - start.b) * clampedRatio);

	return `rgb(${r}, ${g}, ${b})`;
}

export function get_localtime(date: Date | string, disable_hour?: boolean): string {
	const date_obj = typeof date === 'string' ? new Date(date) : date;

	if (!disable_hour) {
		// time format is '2021-10-10, 14:55:33'
		const date_time_str = date_obj.toLocaleString('en-US', { hour12: false });
		return date_time_str;
	} else {
		const date_str = date_obj.toLocaleDateString('en-US');
		return date_str;
	}
}

export function extract_number_from_str(str: string): number | null {
	const stringWithoutCommas = str.replace(/,/g, '');
	const regex = /\d+(\.\d+)?/;
	const match = stringWithoutCommas.match(regex);

	return match ? parseFloat(match[0]) : null;
}

export function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatNumber(num: number) {
	const formatter = new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' });
	return formatter.format(num);
}

export function make_time_str(diff: number) {
	const sec = Math.floor((diff / 1000) % 60);
	const min = Math.floor((diff / (1000 * 60)) % 60);
	const hour = Math.floor((diff / (1000 * 60 * 60)) % 24);
	const day = Math.floor(diff / (1000 * 60 * 60 * 24));

	return `${day} days and ${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
}

export function check_email_format(email: string): boolean {
	const email_reg_exp = /^[a-zA-Z0-9+-\\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
	return email_reg_exp.test(email);
}

export function is_mobile_device(): boolean {
	// for debug // if (process.env.REACT_APP_ENV === 'local') return false;
	const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
	return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}

export function get_upload_image_url_in_blob(uuid: string): string {
	return `https://megabox12.blob.core.windows.net/kyoto/upload/${uuid}.png`;
}

export function get_generated_image_url_in_blob(uuid: string): string {
	return `https://megabox12.blob.core.windows.net/kyoto/gan/${uuid}.png`;
}

export function is_standalone() {
	return (window.navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;
}

export function get_os(): 'ios' | 'android' | 'other' {
	const userAgent = window.navigator.userAgent.toLowerCase();

	if (/iphone|ipad|ipod|mac/.test(userAgent) && navigator.maxTouchPoints > 1) return 'ios';
	else if (/android/.test(userAgent)) return 'android';
	else return 'other';
}

export function is_support_pwa_a2hs(): boolean {
	const os = get_os();
	const ua = window.navigator.userAgent;

	switch (os) {
		case 'ios':
			const iOSVersion = parseFloat((ua.match(/OS (\d+)_(\d+)_?(\d+)?/) || [])[1]);
			return iOSVersion >= 13.0;

		case 'android':
			const chromeVersion = parseInt((ua.match(/Chrome\/(\d+)/) || [])[1]);
			return chromeVersion >= 72;

		default:
			return 'onbeforeinstallprompt' in window;
	}
}

export const prevent_scroll = {
	userSelect: 'none',
	overflowX: 'hidden',
	overflowY: 'hidden',
	touchAction: 'none',
	WebkitOverflowScrolling: 'none',
	overscrollBehavior: 'none',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	'&::-webkit-scrollbar': { display: 'none' },
};

export const allow_scroll_y = {
	overflowX: 'hidden',
	overflowY: 'auto',
	touchAction: 'pan-y',
	WebkitOverflowScrolling: 'none',
	overscrollBehavior: 'contain',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	'&::-webkit-scrollbar': { display: 'none' },
};

export const allow_scroll_x = {
	overflowX: 'auto',
	overflowY: 'hidden',
	touchAction: 'pan-x',
	WebkitOverflowScrolling: 'touch',
	overscrollBehavior: 'contain',
	scrollbarWidth: 'none',
	msOverflowStyle: 'none',
	'&::-webkit-scrollbar': { display: 'none' },
};
