//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { prevent_scroll } from '../common';

//---------------------------------------------------------
// Component
//---------------------------------------------------------
export default function InsetArea(props: { fill_top?: boolean; children: ReactNode }) {
	const { fill_top, children } = props;

	const full_height = '100%'; //is_mobile_device() ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
	const top_padding = !fill_top ? 'env(safe-area-inset-top)' : 0;
	const bottom_padding = 'env(safe-area-inset-bottom)';

	return (
		<Box id="inset-area" width="100%" height={full_height} display="flex" flexDirection="column" alignItems="center" justifyContent="center" paddingTop={top_padding} paddingBottom={bottom_padding} sx={prevent_scroll}>
			{children}
		</Box>
	);
}
