//---------------------------------------------------------
// Imports
//---------------------------------------------------------
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';

//---------------------------------------------------------
// Types
//---------------------------------------------------------
interface RootProps {
	children: React.ReactNode;
}

//---------------------------------------------------------
// Root Component
//---------------------------------------------------------
const theme = createTheme({
	typography: {
		fontFamily: 'PT Serif, serif',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					backgroundColor: 'black',
					height: '100%',
					overflow: 'hidden',
				},
				body: {
					backgroundColor: 'black',
					height: '100%',
					overflow: 'hidden',
					fontFamily: 'PT Serif, serif',
				},
				'#root': {
					height: '100%',
					overflow: 'auto',
					WebkitOverflowScrolling: 'touch',
				},
			},
		},
	},
});

const RootProvider = ({ children }: RootProps) => (
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	</BrowserRouter>
);

//---------------------------------------------------------
// Initialization & Entry Point
//---------------------------------------------------------
const initializeApp = () => {
	const rootElement = document.getElementById('root');

	if (!rootElement) {
		throw new Error('Root element not found');
	}

	const app = (
		<RootProvider>
			<AppRoot />
		</RootProvider>
	);

	// For react-snap: use hydrateRoot if the root element already has children
	if (rootElement.hasChildNodes()) hydrateRoot(rootElement, app);
	else createRoot(rootElement).render(app);
};

// Start the application
initializeApp();

